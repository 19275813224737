import React, { useEffect, useRef } from "react";
import GradeBook from "../../assets/media/GradeBook.png";
import WeatherfulBot from "../../assets/WeatherfulBot/Demo.png";
import OptionBrew from "../../assets/OptionBrew/Demo.png";
import TicTacToe from "../../assets/TicTacToe/Demo.png";
import Portfolio from "../../assets/Portfolio/Demo.png";
import PhotoGallery from "../../assets/PhotoGallery/Demo.png";
import EditDistance from "../../assets/EditDistance/Demo.png";
import Answerd from "../../assets/Answer-d/Demo.png";
import Toohak from "../../assets/Toohak/Demo.png";
import ChessEngine from "../../assets/ChessEngine/feature1.png";
import Leetcode from "../../assets/Leetcode/Demo.png";
import GroceryStore from "../../assets/GroceryStore/Demo.png";
import "./Projects.css";

const projects = [
  {
    title: "Stock Market Broker App",
    description:
      "By leveraging the power of Alpaca APIs, this platform provides real-time market data and comprehensive trading capabilities, enabling users to execute trades, manage watchlists, conduct ACH transfers, and monitor their portfolio's growth. With a focus on secure account management, real-time updates, and efficiency, the platform is equipped to handle high volumes of data and transactions.",
    icons: [
      { name: "Python", icon: "logos:python" },
      { name: "JavaScript", icon: "vscode-icons:file-type-js-official" },
      { name: "React", icon: "logos:react" },
      { name: "Django", icon: "vscode-icons:file-type-django" },
      { name: "Alpaca's Market API", icon: "mdi:finance" },
    ],
    image: OptionBrew,
    demoLink: "#",
    githubLink: "https://github.com/eddayyy/OptionBrew",
  },
  {
    title: "Grocery Store Simulation",
    description:
      "Developed a Grocery Store simulation in C++ with a focus on Object-Oriented Principles and Design. Utilized C++ 20 STL arrays, vectors, doubly linked lists, and singly linked lists for operations like adding items to a cart, pricing, assigning unique UPC codes, and combining grocery lists. Designed and implemented comprehensive unit and regression tests to validate core system functionalities.",
    icons: [
      { name: "C++", icon: "logos:c-plusplus" },
      { name: "Object Oriented Programming", icon: "mdi:brain" },
      { name: "Data Structures", icon: "mdi:database" },
    ],
    image: GroceryStore, // Ensure this link is correct
    demoLink: "#",
    githubLink: "https://github.com/eddayyy/GroceryStore",
  },
  {
    title: "Personal Website",
    description:
      "This personal website is built using ReactJS and deployed on Netlify, with the domain hosted by Cloudflare. The site features a modern design with dark mode support, and showcases various technical projects. It includes interactive elements such as a dynamic canvas background and smooth scrolling effects. The codebase leverages reusable React components and state management to ensure a seamless user experience.",
    icons: [
      { name: "JavaScript", icon: "vscode-icons:file-type-js-official" },
      { name: "HTML", icon: "vscode-icons:file-type-html" },
      { name: "CSS", icon: "vscode-icons:file-type-css" },
      { name: "Cloudflare", icon: "devicon:cloudflare" },
    ],
    image: Portfolio,
    demoLink: "#",
    githubLink: "https://github.com/eddayyy/personal-website",
  },
  {
    title: "Weatherful-Bot",
    description:
      "A Python-based automated weather alert system, developed and deployed to AWS Lambda to provide real-time weather tweets for Fullerton, CA. This bot leverages APIs from Twitter/X and Weatherbit to retrieve weather data, process it into human readable tweets, and tweet the data in real time. The automation is orchestrated through Amazon Event Bridge.",
    icons: [
      { name: "Python", icon: "logos:python" },
      { name: "AWS", icon: "logos:aws" },
      { name: "AWS Lambda", icon: "logos:aws-lambda" },
    ],
    image: WeatherfulBot,
    demoLink: "https://twitter.com/WeatherfulBot",
    githubLink: "https://github.com/eddayyy/WeatherFul-Bot",
  },
  {
    title: "Chess Engine",
    description:
      "Chess Engine is a C++ recreation of chess where users can play against each other. This project heavily involved Object Oriented Programming to control the game state, each piece's movement mechanics at any given position, and turn taking. The game utilizes the Simple Directmedia Layer framework to render the chess board and pieces.",
    icons: [
      { name: "C++", icon: "logos:c-plusplus" },
      { name: "Visual Studio", icon: "logos:visual-studio" },
    ],
    image: ChessEngine,
    demoLink: "#",
    githubLink: "https://github.com/eddayyy/Chess",
  },
  {
    title: "Leetcode Interview Prep / Solutions",
    description:
      "My Data Structures & Algorithms practice arena where I solve Easy, Medium, and Hard level challenges in preparation for coding interviews. My solutions are written in a mix of C++, Python, and JavaScript. ( P.S. I prefer Python for coding interviews due to it's readability & concise syntax! )",
    icons: [
      { name: "C++", icon: "logos:c-plusplus" },
      { name: "Python", icon: "logos:python" },
      { name: "JavaScript", icon: "vscode-icons:file-type-js-official" },
      { name: "Leetcode", icon: "tabler:brand-leetcode" },
    ],
    image: Leetcode,
    demoLink: "https://leetcode.eduardonunez.dev",
    githubLink: "https://github.com/eddayyy/Leetcode",
  },
  {
    title: "Answer-D: A CSUF Q&A Platform",
    description:
      "Answer-D, a vibrant and student-centric application, provides a platform exclusive for CSUF (California State University, Fullerton) students to post and interact with academic questions and answers. My role in this group project was as a Frontend Developer where I designed the UI and implemented certain frontend pages. In addition to that I participated in the overall design of the project's architecture. This platform leverages HTML, CSS, and JavaScript to offer a responsive and intuitive user interface.",
    icons: [
      { name: "HTML", icon: "vscode-icons:file-type-html" },
      { name: "CSS", icon: "vscode-icons:file-type-css" },
      { name: "JavaScript", icon: "vscode-icons:file-type-js-official" },
    ],
    image: Answerd,
    demoLink: "https://answer-362.github.io/Answer-d/",
    githubLink: "https://github.com/eddayyy/Answer-d",
  },
  {
    title: "GradeBook GUI Application",
    description:
      "This gradebook application is written in Python and built on the PyQt GUI Framework, it serves to aid educators in handling student grades by streamlining the grading procedure. Some of it's offerings are in-depth data analysis to shed light on both individual student and overall class performance such as average grades, standard deviations, visual representations, etc.",
    icons: [
      { name: "Python", icon: "logos:python" },
      { name: "PyQt GUI Framework", icon: "logos:qt" },
    ],
    image: GradeBook,
    demoLink: "#",
    githubLink: "https://github.com/eddayyy/Gradebook-Application",
  },
  {
    title: "Photo Gallery Web App",
    description:
      "A Full-Stack React and Flask based application that allows users to securely manage and display their photo collections. It integrates functionalities such as user authentication, image uploading, and image retrieval, utilizing React for the frontend and MongoDB as the NoSQL database.",
    icons: [
      { name: "React", icon: "logos:react" },
      { name: "Flask", icon: "logos:flask" },
      { name: "MongoDB", icon: "vscode-icons:file-type-mongo" },
    ],
    image: PhotoGallery,
    demoLink: "#",
    githubLink: "https://github.com/eddayyy/PhotoGalleryApp",
  },
  {
    title: "Toohak",
    description:
      "A Full-Stack dynamic online quiz platform inspired by Kahoot, developed with Firebase and Bootstrap. It offers engaging experiences for quiz creators and participants, suitable for educational and team-building activities.",
    icons: [
      { name: "HTML", icon: "vscode-icons:file-type-html" },
      { name: "CSS", icon: "vscode-icons:file-type-css" },
      { name: "JavaScript", icon: "vscode-icons:file-type-js-official" },
      { name: "Firebase", icon: "logos:firebase" },
      { name: "Bootstrap", icon: "logos:bootstrap" },
    ],
    image: Toohak,
    demoLink: "https://toohak-a8f56.web.app/",
    githubLink: "https://github.com/eddayyy/Toohak",
  },
  {
    title: "Artificially Intelligent Tic-Tac-Toe",
    description:
      "Two Tic-Tac-Toe Games developed in C++. Version 1: Console representation of a tic-tac-toe game prompting the user for input, the user can play another player on the same device. Version 2: Implements the Alpha-Beta Pruning algorithm and a game state evaluation algorithm to determine winning moves for the AI player.",
    icons: [{ name: "C++", icon: "logos:c-plusplus" }],
    image: TicTacToe,
    demoLink: "#",
    githubLink: "https://github.com/eddayyy/TicTacToe",
  },

  {
    title: "Edit Distance Calculator",
    description:
      "The Edit Distance Calculator serves as a tool designed to measure and spot the differences between two genetic sequences. This algorithm is crucial in Computational Bioinformatics for analyzing genetic variations. By utilizing a dynamic programming approach the algorithm precisely and efficiently calculates the necessary insertions, deletions, and substitutions to transform one sequence into another and provides a detailed visualization of these changes to facilitate the understanding of sequence alignment techniques.",
    icons: [
      { name: "Python", icon: "logos:python" },
      { name: "Matrix Calculations", icon: "mdi:matrix" },
      {
        name: "Data Structures & Dynamic Programming Algorithms",
        icon: "mdi:brain",
      },
    ],
    image: EditDistance,
    demoLink: "#",
    githubLink: "https://github.com/eddayyy/EditDistance",
  },
];

const Projects = () => {
  const textRefs = useRef([]);
  const imageRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    textRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    imageRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      textRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
      imageRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <section id="projects">
      <div className="z-10 font-bold text-2xl py-20" id="my-projects">
        My Projects
      </div>
      {projects.map((project, index) => (
        <div key={index} className="project-container">
          <div
            className="project-description fade-in"
            ref={(el) => {
              if (el) textRefs.current[index] = el;
            }}
          >
            <h3 className="project-title">{project.title}</h3>
            <div className="project-made-with">
              <span>Made with:</span>
              <div className="project-icons">
                {project.icons.map((icon, idx) => (
                  <iconify-icon
                    key={idx}
                    icon={icon.icon}
                    data-tooltip={icon.name}
                  ></iconify-icon>
                ))}
              </div>
            </div>
            <p className="project-text">{project.description}</p>
            <div className="project-buttons">
              {project.demoLink !== "#" && (
                <a
                  target="_"
                  href={project.demoLink}
                  className="project-button"
                >
                  <iconify-icon
                    icon="bi:play-circle"
                    className="project-button icon"
                  ></iconify-icon>
                  Demo
                </a>
              )}
              <a
                target="_"
                href={project.githubLink}
                className="project-button github"
              >
                <iconify-icon
                  icon="mdi:github"
                  className="project-button icon"
                ></iconify-icon>
                GitHub
              </a>
            </div>
          </div>
          <img
            src={project.image}
            alt={`${project.title} Demo`}
            className="project-image drop-in"
            ref={(el) => {
              if (el) imageRefs.current[index] = el;
            }}
          />
        </div>
      ))}
    </section>
  );
};

export default Projects;
