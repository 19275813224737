import React, { useState, useEffect, useRef } from "react";
import "./Contact.css";
const Contact = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const currentSectionRef = sectionRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data).toString(),
    })
      .then(() => setIsSubmitted(true))
      .catch((error) => alert(error));
  };

  return (
    <section id="contact" ref={sectionRef}>
      <h2 className="font-bold text-2xl py-10">Contact</h2>
      <div className="contact-section shadow-md hover:shadow-lg">
        <p className="text-neutral-500 dark:text-neutral-300 mb-4">
          If you'd like to get in touch, feel free to email me at
          <a href="mailto:eduardonunez.eng@gmail.com" className="text-blue-500">
            {" "}
            eduardonunez.eng@gmail.com
          </a>
          .
        </p>
        {isSubmitted ? (
          <p className="text-green-500">
            Thank you for your message, I will get back to you as soon as
            possible!
          </p>
        ) : (
          <form
            className="contact-form"
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />

            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="4" required></textarea>

            <button type="submit">Send Message</button>
          </form>
        )}
      </div>
    </section>
  );
};

export default Contact;
