import React from "react";
import headshot from "../../assets/media/headshot.jpeg";
import CanvasBackground from "../CanvasBackground/CanvasBackground.jsx";
import "./Header.css";

const Header = () => {
  return (
    <header id="home" className="header-background">
      <CanvasBackground />
      <div
        className="picture-container"
        style={{ position: "relative", zIndex: 1 }}
      >
        <img src={headshot} alt="profile" />
      </div>
      <p className="intro-text" style={{ position: "relative", zIndex: 1 }}>
        Hello, I'm Eddie! I hold a Bachelor of Science degree in Computer
        Science from California State University, Fullerton. I currently work as
        a Solutions Engineer at Capital Group, where I design and implement
        high-performance, low-latency software solutions. My focus is on
        optimizing real-time data processing and workflow efficiency to enhance
        decision-making and deliver critical business value in front-office
        trading environments.
      </p>
      <div className="social-icons" style={{ position: "relative", zIndex: 1 }}>
        <a
          href="https://github.com/eddayyy"
          target="_blank"
          rel="noopener noreferrer"
          className="github"
        >
          <iconify-icon
            icon="line-md:github"
            className="github-icon"
          ></iconify-icon>
        </a>
        <a
          href="https://www.linkedin.com/in/eduardong"
          target="_blank"
          rel="noopener noreferrer"
          className="linkedin"
        >
          <iconify-icon
            href="https://www.linkedin.com/in/eduardong"
            icon="line-md:linkedin"
            className="linkedin-icon"
          ></iconify-icon>
        </a>
      </div>
    </header>
  );
};

export default Header;
