import React from "react";
import Navbar from "./components/Navbar/Navbar.jsx";
import Header from "./components/Header/Header.jsx";
import Projects from "./components/Projects/Projects.jsx";
import Skills from "./components/Skills/Skills.jsx";
import Experience from "./components/Experience/Experience.jsx";
import Education from "./components/Education/Education.jsx";
import Contact from "./components/Contact/Contact.jsx";
import BackToTopButton from "./components/BackToTopButton/BackToTopButton.jsx";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Education />
      <hr />
      <Experience />
      <hr />
      <Projects />
      <hr />
      <Skills />

      <hr />

      <Contact />
      <BackToTopButton />
    </div>
  );
};

export default App;
