import React, { useRef, useEffect } from "react";

const CanvasBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const particlesArray = [];
    const numberOfParticles = 120;
    const maxDistanceBase = 130; // Base maximum distance for drawing lines between particles
    let maxDistance = maxDistanceBase;
    const mouse = {
      x: null,
      y: null,
      radius: 150, // Radius of effect around the mouse
      clickRadius: 100, // Radius of effect when mouse is clicked
      click: false,
    };

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      maxDistance = Math.min(
        (window.innerWidth * window.innerHeight) / 10000,
        maxDistanceBase
      );
      init();
    };

    const Particle = function (x, y, directionX, directionY, size, color) {
      this.x = x;
      this.y = y;
      this.directionX = directionX;
      this.directionY = directionY;
      this.size = size;
      this.color = color;
      this.repelling = false;
      this.repellingSpeed = 0;

      this.draw = function () {
        ctx.save();
        ctx.translate(this.x, this.y);
        ctx.rotate((45 * Math.PI) / 180);
        ctx.fillStyle = this.color;
        ctx.fillRect(-this.size / 2, -this.size / 2, this.size, this.size);
        ctx.restore();
      };

      this.update = function () {
        if (this.x + this.size > canvas.width || this.x - this.size < 0) {
          this.directionX = -this.directionX;
        }

        if (this.y + this.size > canvas.height || this.y - this.size < 0) {
          this.directionY = -this.directionY;
        }

        if (mouse.click && !this.repelling) {
          const dx = mouse.x - this.x;
          const dy = mouse.y - this.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < mouse.clickRadius) {
            this.repelling = true;
            this.repellingSpeed = 5;
          }
        }

        if (this.repelling) {
          const dx = this.x - mouse.x;
          const dy = this.y - mouse.y;
          const angle = Math.atan2(dy, dx);
          this.directionX = Math.cos(angle) * this.repellingSpeed;
          this.directionY = Math.sin(angle) * this.repellingSpeed;
          this.repellingSpeed *= 0.95;
          if (this.repellingSpeed < 0.1) {
            this.repelling = false;
          }
        }

        this.x += this.directionX;
        this.y += this.directionY;

        this.draw();
      };
    };

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    window.addEventListener("resize", () => {
      setCanvasSize();
    });

    window.addEventListener("mousemove", (event) => {
      mouse.x = event.x;
      mouse.y = event.y;
    });

    window.addEventListener("click", (event) => {
      mouse.x = event.x;
      mouse.y = event.y;
      mouse.click = true;
      setTimeout(() => {
        mouse.click = false;
      }, 200);
    });

    function init() {
      particlesArray.length = 0;
      for (let i = 0; i < numberOfParticles; i++) {
        const size = Math.random() * 5 + 2;
        const x = Math.random() * (canvas.width - size * 2) + size;
        const y = Math.random() * (canvas.height - size * 2) + size;
        const directionX = Math.random() * 0.4 - 0.2;
        const directionY = Math.random() * 0.4 - 0.2;
        const color = "#ffffff"; // White color for particles

        particlesArray.push(
          new Particle(x, y, directionX, directionY, size, color)
        );
      }
    }

    function drawLines() {
      for (let i = 0; i < particlesArray.length; i++) {
        for (let j = i + 1; j < particlesArray.length; j++) {
          const dx = particlesArray[j].x - particlesArray[i].x;
          const dy = particlesArray[j].y - particlesArray[i].y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < maxDistance) {
            ctx.beginPath();
            ctx.strokeStyle = "rgba(255, 255, 255, 0.4)";
            ctx.lineWidth = 1;
            ctx.moveTo(particlesArray[i].x, particlesArray[i].y);
            ctx.lineTo(particlesArray[j].x, particlesArray[j].y);
            ctx.stroke();
          }
        }

        if (mouse.x && mouse.y) {
          const distanceToMouse = Math.sqrt(
            (particlesArray[i].x - mouse.x) ** 2 +
              (particlesArray[i].y - mouse.y) ** 2
          );

          if (distanceToMouse < maxDistance) {
            ctx.beginPath();
            ctx.strokeStyle = "rgba(255, 255, 255, 0.4)";
            ctx.lineWidth = 1;
            ctx.moveTo(particlesArray[i].x, particlesArray[i].y);
            ctx.lineTo(mouse.x, mouse.y);
            ctx.stroke();
          }
        }
      }
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      drawLines();
      for (let i = 0; i < particlesArray.length; i++) {
        particlesArray[i].update();
      }
      requestAnimationFrame(animate);
    }

    setCanvasSize();
    animate();
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 0,
        width: "100%",
        height: "100%",
      }}
    />
  );
};

export default CanvasBackground;
