import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import "./Skills.css";

const skills = [
  { name: "C", icon: "devicon:c" },
  { name: "C++", icon: "devicon:cplusplus" },
  { name: "Python", icon: "logos:python" },
  { name: "JavaScript", icon: "vscode-icons:file-type-js-official" },
  { name: "HTML", icon: "skill-icons:html" },
  { name: "CSS", icon: "vscode-icons:file-type-css" },
  { name: "Assembly", icon: "file-icons:assembly-intel" },
  { name: "AWS", icon: "logos:aws" },
  { name: "React", icon: "logos:react" },
  { name: "Bootstrap", icon: "logos:bootstrap" },
  { name: "Django", icon: "vscode-icons:file-type-django" },
  { name: "Flask", icon: "logos:flask" },
  { name: "Linux", icon: "flat-color-icons:linux" },
  { name: "Firebase", icon: "logos:firebase" },
  { name: "MongoDB", icon: "vscode-icons:file-type-mongo" },
  { name: "MySQL", icon: "logos:mysql" },
  { name: "Git", icon: "devicon:git" },
  { name: "Alpaca API", icon: "mdi:finance" },
  { name: "Twitter/X API", icon: "mdi:twitter" },
  { name: "Visual Studio", icon: "logos:visual-studio" },
  { name: "Qt", icon: "logos:qt" },
  { name: "Cloudflare", icon: "devicon:cloudflare" },
  { name: "Weatherbit API", icon: "mdi:weather-partly-cloudy" },
  { name: "VS Code", icon: "logos:visual-studio-code" },
  { name: "Slack", icon: "devicon:slack" },
  { name: "Amazon EventBridge", icon: "mdi:aws" },
];

const Skills = () => {
  const controls = useAnimation();
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start("visible");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(sectionRef.current);

    return () => observer.disconnect();
  }, [controls]);

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        type: "spring",
        stiffness: 100,
      },
    }),
  };

  return (
    <section id="skills" ref={sectionRef}>
      <h2 className="font-bold text-2xl py-10">My Skills</h2>
      <div className="skills-container">
        {skills.map((skill, index) => (
          <motion.div
            key={skill.name}
            className="skills-icon-container"
            custom={index}
            initial="hidden"
            animate={controls}
            variants={variants}
          >
            <iconify-icon
              className="skills-icon"
              icon={skill.icon}
            ></iconify-icon>
            <div className="skills-label">{skill.name}</div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
